import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import TextSection from '../components/_shared/TextSection/TextSection'
import HowItWorksSection from '../components/_shared/HowItWorksSection/HowItWorksSection'
import ContactFormSection from '../components/_shared/ContactFormSection/ContactFormSection'
import AutomatSection from '../components/_shared/AutomatSection/AutomatSection'
import RedBar from '../components/_shared/RedBar/RedBar'

const textSectionContent =
  'Automaty vendingowe to innowacyjna alternatywa dla klasycznych sposobów zaopatrzenia przemysłu. Dzięki temu rozwiązaniu w Twoim przedsiębiorstwie nie zabraknie artykułów BHP, które pomogą chronić zdrowie i życie Twoich pracowników. Automaty zapewniają także całkowitą kontrolę nad wydawanymi produktami.<br/><br/>Nowoczesne systemy wewnątrzzakładowe, poza ochroną bezpieczeństwa, służą również optymalizacji zużycia roboczej odzieży i innych środków ochrony. Vending BHP pozwala na zmniejszenie kosztów związanych z ich wydawaniem, ewidencjonowaniem i używaniem.<br/><br/>Zapytaj o możliwość kupna lub wynajmu automatu vendingowego – już od 1 zł miesięcznie. Dobierzemy wygodny, elastyczny model rozliczenia tak, aby współpraca była dla Ciebie jak najbardziej komfortowa.'

  const LSBOXBPContent =
  'LSBOX BP, to profesjonalne urządzenie do wydawania artykułów BHP, narzędzi, leków lub innych produktów wymagających nadzoru. Dystrybutor wyposażony jest w komputer z zainstalowanym oprogramowaniem zarządzającym, ekran dotykowy, UPS. Zasada działania oparta jest na obrocie metalowego bębna wyposażonego w elektronicznie nadzorowane lokalizacje. Automat ten oferuje od 90 do 468 lokalizacji. LSBOX BP może występować jako moduł główny (z komputerem i ekranem) lub moduł dodatkowy. Moduł główny może zostać rozbudowany o kolejne moduły bębnowe, szufladowe i szafkowe, nawet do 10 jednostek.'
const LSBOXDBContent =
  'Automat LSBOX DB to profesjonalne urządzenie do wydawania artykułów BHP, narzędzi, leków lub innych produktów wymagających nadzoru. Dystrybutor wyposażony jest w komputer z zainstalowanym oprogramowaniem zarządzającym, ekran dotykowy, UPS. Zasada działania oparta jest na obrocie metalowego bębna wyposażonego w elektronicznie nadzorowane lokalizacje. Automat ten oferuje od 90 do 702 lokalizacji. LSBOX DB może występować jako moduł główny (z komputerem i ekranem) lub moduł dodatkowy. Moduł główny może zostać rozbudowany o kolejne moduły bębnowe, szufladowe i szafkowe, nawet do 10 jednostek.'
const LSBOX12Content =
  'Automat sprężynowy LSBOX 12 jest urządzeniem do automatycznego wydawania do 72 różnych typów produktów. Oparty na podajnikach sprężynowych umożliwia przechowywanie bardzo dużej ilości produktów na małej powierzchni. Produkty widoczne są przez przednią podwójna szybę niskoemisyjną. Pobieranie produktu dokonujemy za pomocą klawiatury numerycznej. Wszystkie operacje widoczne są na wyświetlaczu graficznym. Serwomechanizmy dystrybucyjne kontrolowane są przez płytę główną z mikroprocesorem. Umożliwia ona programowanie i modyfikowanie parametrów automatu.'

const MachinesPage = () => {
  const data = useStaticQuery(graphql`{
  LSBOXBP: file(relativePath: {eq: "LSBOXBP.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, height: 500, layout: FULL_WIDTH)
    }
  }
  LSBOXDB: file(relativePath: {eq: "LSBOXDB.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, height: 500, layout: FULL_WIDTH)
    }
  }
  LSBOX12: file(relativePath: {eq: "LSBOX12.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, height: 500, layout: FULL_WIDTH)
    }
  }
}
`)

  return (
    <Layout pageTitle="Automaty BHP" headerImage={3}>
      <div className="container container--page">
        <TextSection contentTitle="" contentText={textSectionContent} />
        <HowItWorksSection onlySteps />
      </div>
      <div className="background--silver">
        <div className="container container--page">
          <AutomatSection
            title="LSBOX BP"
            content={LSBOXBPContent}
            imageFluid={data.LSBOXBP.childImageSharp.gatsbyImageData}
          />
          <AutomatSection
            title="LSBOX DB"
            content={LSBOXDBContent}
            imageFluid={data.LSBOXDB.childImageSharp.gatsbyImageData}
          />
          <AutomatSection
            title="LSBOX 12"
            content={LSBOX12Content}
            imageFluid={data.LSBOX12.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      <ContactFormSection transparent />
      <RedBar id="automaty-bhp" textHtml="Potrzebujesz więcej informacji o vendingu BHP?" />
    </Layout>
  );
}

export default MachinesPage
