import React from 'react'
import PropTypes from 'prop-types'
import './AutomatSection.scss'
import ImageSection from '../ImageSection/ImageSection'

const AutomatSection = ({ title, content, imageFluid }) => (
  <div className="automatSection">
    <div className="automatSection__column automatSection__column--left">
      <h3 className="automatSection__title">{title}</h3>
      <div className="automatSection__context">{content}</div>
    </div>
    <div className="automatSection__column automatSection__column--right">
      <ImageSection alt={title} sectionClass="automatSection__image" imageFluid={imageFluid} />
    </div>
  </div>
)

AutomatSection.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  imageFluid: PropTypes.shape(),
}

AutomatSection.defaultProps = {
  title: '',
  content: '',
  imageFluid: null,
}

export default AutomatSection
